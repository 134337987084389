<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />

    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          v-model:value="start_date"
          :disabled-date="disabledStartDate"
          format="DD MMMM YYYY"
          placeholder="Start Date"
        />
        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          v-model:value="end_date"
          :disabled-date="disabledEndDate"
          format="DD MMMM YYYY"
          placeholder="End Date"
        />

        <!-- <filter-jenis
          class="mt-2 mr-2"
          placeholder="Pilih Jenis API"
          v-model:value="jenis"
        ></filter-jenis> -->

        <a-select
          :options="[
            { value: 1, label: 'Sukses' },
            { value: 0, label: 'Gagal' },
          ]"
          :mode="null"
          allow-clear
          class=" mt-2 mr-2"
          style="width: 300px;"
          placeholder="Pilih Status"
          v-model:value="status"
        >
        </a-select>
        <a-select
          :mode="null"
          allow-clear
          class=" mt-2 mr-2"
          style="width: 300px;"
          placeholder="Pilih Jenis Table"
          v-model:value="tableFilter"
          :options="[
            { value: `vendor`, label: 'Vendor' },
            { value: `product_uom`, label: 'Product UOM' },
            { value: `wilayah`, label: 'Wilayah' },
            { value: `vendor_customer`, label: 'Vendor Customer' },
            { value: `area`, label: 'Area' },
            { value: `product`, label: 'Product' },
            { value: `warehouse`, label: 'Warehouse' },
          ]"
        >
        </a-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          v-can:hide.distributor
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button
          class="mt-2 mr-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
        <!-- <a-button
          v-can:hide.distributor
          class="ml-2 mr-2"
          type="primary"
          title="syncron manual"
          @click="
            () => {
              formState = {}
              modal2Visible = true
              formState.title_state = 'Synchronize'
            }
          "
        >
          <i class="fa fa-refresh" aria-hidden="true"></i>
        </a-button> -->
      </div>
    </div>
    <!-- <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="data"
                size="small"
                :row-selection="rowSelection"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                bordered
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template
                  #filterDropdown="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                >
                  <div class="custom-filter-dropdown">
                    <a-input
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                      @pressEnter="() => handleSearch(selectedKeys, confirm)"
                    />
                    <a-button
                      type="primary"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="() => handleSearch(selectedKeys, confirm)"
                      >Search</a-button
                    >
                    <a-button
                      size="small"
                      style="width: 90px"
                      @click="() => handleReset(clearFilters)"
                      >Reset</a-button
                    >
                  </div>
                </template>
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #customer="{ text }">
                  <span v-if="searchText">
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                      <mark
                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
                  </template>
                </template>
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #status="{ text }">
                  <a-tag v-if="text.status === 1" color="#108ee9">Sukses</a-tag>
                  <a-tag v-else color="grey">Gagal</a-tag>
                </template>
                <template #action="{record}">
                  <span>
                    <a-tooltip title="Syncron">
                      <a
                        @click="syncrons(record)"
                        href="javascript: void(0);"
                        class="btn btn-sm btn-light mr-1"
                      >
                        <small> <i class="fa fa-refresh"></i> </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Ubah Data Syncron" v-if="record.status === 0">
                      <a
                        href="javascript: void(0);"
                        class="btn btn-sm btn-light"
                        @click="
                          () => {
                            formState = {}
                            modalEditVisible = true
                            formState.title_state = 'Edit Data Synchronize'
                            syncronsEdit(record)
                          }
                        "
                      >
                        <small>
                          <i class="fe fe-edit" />
                        </small>
                      </a>
                    </a-tooltip>
                    <!-- <a-tooltip title="Log History">
                      <a-button
                        class="button"
                        size="small"
                        @click="detailSyncrons(record.id, record.trans_date)"
                      >
                        <i class="fa fa-history"></i>
                      </a-button>
                    </a-tooltip> -->
                  </span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-body"><vb-controls-pagination /></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-body"><vb-controls-pagination-2 /></div>
        </div>
      </div>
    </div>

    <!-- syncrons manual -->
    <!-- <a-modal
      v-model:visible="modal2Visible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"

    >
      <a-form
          :wrapper-col="{ span: 14 }"
          :scroll-to-first-error="true"
          class="myform"
          ref="formRef"
          :rules="rules"
          :model="formState"
          >
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Distributor"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              :max-tag-count="10"
              allow-clear
            >
              <select-distributor
                show-search
                label="fullname"
                style="width: 100%"
                v-model:value="syncDistributors"
                :disabled="vendorId?true:false"
              ></select-distributor>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Tipe API"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
            >
              <a-select
                v-model:value="syncType"
                placeholder="Pilih Tipe"
                mode="multiple"
                allow-clear
                option-filter-prop="label"
                option-label-prop="label"
                @search="onSearch"
                :options="[
                  { value: 'stock', label: 'Stok' },
                  { value: 'sales', label: 'Penjualan' },
                  { value: 'gr', label: 'Penerimaan' },
                ]">
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
         <a-row class="form-row">
          <a-col :md="12" :sm="24">
            <a-form-item
              label="Tgl Awal"
              label-align="left"
              :label-col="{ md: { span: 8 }, sm: { span: 4 } }"
              :wrapper-col="{ md: { span: 12 - 8 }, sm: { span: 24 - 4 } }"
              allow-clear
            >
            <a-date-picker
              style="width: 300px;"
              v-model:value="sync_start_date"
              format="DD MMMM YYYY"
              placeholder="Start Date"
            />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item
              label="Tgl Akhir"
              label-align="left"
              :label-col="{ md: { span: 7 }, sm: { span: 4 } }"
              :wrapper-col="{ md: { span: 12 - 7 }, sm: { span: 24 - 4 } }"
              allow-clear
            >
            <a-date-picker
              style="width: 300px;"
              v-model:value="sync_end_date"
              format="DD MMMM YYYY"
              placeholder="End Date"
            />
            </a-form-item>
          </a-col>
        </a-row>
         <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Reason"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              required
              name="syncNote"
            >
            <a-input
              stylea="width: 300px;"
              v-model:value="formState.syncNote"
            />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Result"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              allow-clear
            >
            </a-form-item>
          </a-col>
          <a-col :sm="24">
            <div class="card">
              <div class="card-body" style="background: #e3e3e3;">
                <pre id="resultApi"></pre>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          @click="syncData"
          :loading="isSync"
          >{{formState.title_state}}
        </a-button>
      </template>
    </a-modal> -->

    <!-- syncrons detail -->
    <!-- <a-modal
      v-model:visible="modal3Visible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
    >
      <md-table
        :data-source="logs"
        :columns="logcolumns"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: logMeta.totalCount,
          pageSize: logMeta.perPage,
          current: logMeta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
        :loading="isLoadData"
      >
        <template #no="{ index }">
          <span>
            {{ 0 + index }}
          </span>
        </template>
        <template #transdate="{ text }">
          <span>{{ moment(text).format('DD MMMM YYYY') }}</span>
        </template>
        <template #syncdate="{ text }">
          <span>{{ moment(text).format('DD MMMM YYYY H:mm:ss') }}</span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text === 1" color="#108ee9">Sukses</a-tag>
          <a-tag v-else color="grey">Gagal</a-tag>
        </template>
        <template #expandedRowRender="{index}">
          <md-table
            :columns="innerColumns"
            :data-source="[logs[index]]"
            _row-key="id"
            :pagination="false"
          >
          </md-table>
        </template>
      </md-table>
      <a-col :sm="24">
            <div class="card">
              <div class="card-body" style="background: #e3e3e3;">
                <pre id="detail"></pre>
              </div>
            </div>
          </a-col>
      <template #footer>
        <a-button key="cancel" @click="modal3Visible = false">Close</a-button>
      </template>
    </a-modal> -->

    <!-- syncrons edit -->
    <a-modal
      v-model:visible="modalEditVisible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="678px"
      :mask-closable="false"
      :confirm-loading="isSync"
    >
      <a-form
        :wrapper-col="{ span: 14 }"
        :scroll-to-first-error="true"
        class="myform"
        ref="formRef"
        :model="formState"
      >
        <a-row class="form-row">
          <a-col :sm="24">
            <!-- <a-form-item
              :label="'Data Model'"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
              name="syncNote"
            >

              <a-input class="w-100" v-model:value="objectEdit.External_Id__c" />
            </a-form-item> -->

            <a-textarea
              rows="20"
              cols="50"
              v-model:value="objectEdit"
              placeholder="Data Model"
              allow-clear
            />
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <a-button key="submit" type="primary" @click="syncEditData(syncronsId)" :loading="isSync"
          >{{ formState.title_state }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  watch,
  onMounted,
  reactive,
  toRefs,
  computed,
  onBeforeMount,
  toRef,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterFungsi from '@/components/filter/FilterFungsi'
import SelectDistributor from '@/components/select/SelectDistributor'
import FilterJenis from '@/components/filter/FilterJenisApi'
import qs from 'qs'
import moment, { Moment } from 'moment'
import store from '@/store'
import acolumns from './columns'
import { useDisabledDate } from '@/composables/DisabledDate'
import useUserInfo from '@/composables/useUserInfo'

// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default defineComponent({
  components: {
    // FilterDistributor,
    // FilterFungsi,
    // SelectDistributor,
    // FilterJenis,
  },
  setup() {
    const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)
    const tableFilter = ref(null)
    const status = ref(null)
    const jenis = ref([])
    const fungsi = ref([])
    const formRef = ref('')
    const formStateEditTitle = ref([])
    const objectEdit = ref({})

    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const modalEditVisible = ref(false)

    const isLoadData = ref(false)
    const logPerPage = ref(10)
    const logPageCount = ref(0)
    const logTotalCount = ref(0)
    const logMeta = ref({})
    const logPage = ref(1)
    const logs = ref([])
    const logcolumns = [
      {
        title: 'Tgl Trans',
        dataIndex: 'transdate',
        slots: { customRender: 'transdate' },
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Tgl Sync',
        dataIndex: 'time',
        slots: { customRender: 'syncdate' },
      },
      {
        title: 'Note',
        dataIndex: 'note',
      },
      {
        title: 'User',
        dataIndex: 'user',
      },
      {
        title: 'Status',
        slots: { customRender: 'status' },
      },
      {
        title: 'Count Data',
        dataIndex: 'data_count',
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
      },
    ]
    const innerColumns = [
      {
        title: 'Message',
        dataIndex: 'message',
      },
    ]

    const q = ref('')
    const distributors = ref([])
    const distri = ref(store.state.user.vendor_id)
    const distributor = ref([])
    const dateFormat = 'YYYY-MM-DD'
    const formState = ref({})
    const syncNote = ref('')
    const syncDistributors = ref([])
    const syncType = ref([])
    const syncFunction = ref([])
    const sync_start_date = ref(Moment)
    const sync_end_date = ref(Moment)
    const syncronsId = ref(null)
    if (isDistributor.value) {
      distributors.value.push(vendor_id.value)
    }

    const vendorId = ref(store.state.user.vendor_id)
    vendorId.value !== null ? (syncDistributors.value = store.state.user.vendor_id) : ''

    const errorMessage = ref(null)
    const {
      startDate,
      endDate,
      start_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()

    start_date.value = moment(new Date()).startOf('month')
    end_date.value = moment(new Date())
    columns.value = acolumns

    const params = ref({})

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        start_date: startDate.value,
        end_date: endDate.value,
        distributor: distributors.value,
        q: q.value,
        status: status.value,
        type: jenis.value,
        fungsi: fungsi.value,
        table: tableFilter.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/synchrons-sfdc', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          items.forEach((item, i) => {
            item.key = i
          })
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/syncrons-sfdc', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `syncrons-sdfc_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    // const syncData = async () => {
    //   // const _params = {
    //   //   from: moment(sync_start_date.value).format(dateFormat),
    //   //   to: moment(sync_end_date.value).format(dateFormat),
    //   //   function: syncFunction.value,
    //   //   type: syncType.value,
    //   //   vendor_id: syncDistributors.value,
    //   // }
    //    try {
    //     await formRef.value.validate()
    //   } catch (error) {
    //     console.log(error)
    //     return
    //   }

    //   state.isSync = true
    //   apiClient
    //     .post('', {
    //       from: moment(sync_start_date.value).format(dateFormat),
    //       to: moment(sync_end_date.value).format(dateFormat),
    //       function: syncFunction.value,
    //       type: syncType.value,
    //       vendor_id: syncDistributors.value,
    //       note: formState.value.syncNote,
    //     })
    //     .then(response => {
    //       const { items, _meta } = response.data
    //       var resultApi = document.getElementById('resultApi')
    //       resultApi.innerHTML = JSON.stringify(response.data, undefined, 2)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    //     .finally(() => {
    //       state.isSync = false
    //     })
    // }

    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }
    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isSync: false,
      isDownloading: false,
      syncNote: '',
    })

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const onSearch = value => {
      findText.value = value
    }

    const diff_hours = (dt2, dt1) => {
      const time2 = new Date(dt2)
      const time1 = new Date(dt1)
      var diff = (time2.getTime() - time1.getTime()) / 1000
      diff /= 60 * 60
      return Math.abs(Math.round(diff))
    }

    const cek_kuncian = (dateStart, dateEnd, dateCheck) => {
      var d_1 = dateStart.split('/')
      var d_2 = dateEnd.split('/')
      var d_3 = dateCheck.split('/')

      var from = new Date(d_1[2], parseInt(d_1[1]) - 1, d_1[0])
      var to = new Date(d_2[2], parseInt(d_2[1]) - 1, d_2[0])
      var check = new Date(d_3[2], parseInt(d_3[1]) - 1, d_3[0])
      return check > from && check < to
    }

    const do_syncrons = (id, trans_date, time) => {
      errorMessage.value = null
      // if (time) {
      //   const today = new Date();
      //   const dateToday = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      //   const timeToday = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      //   const dateTimeToday = dateToday +' '+ timeToday;
      //   const diff = diff_hours(time, dateTimeToday);
      //   // if (diff <= 3) {
      //   //   errorMessage.value = `Mohon menunggu ${3-diff} jam setelah sync sebelumnya`
      //   //   return
      //   // }
      // }
      apiClient
        .post(`/api/synchrons-sfdc/${id}/resynch`)
        .then(({ data }) => {
          console.log('response', data)
          modal1Visible.value = false
          fetchData()
          message.success('Berhasil disimpan')
        })
        .catch(e => message.error('Gagal syncron!'))
    }

    const detailSyncrons = (id, date, trans_date, vendor, funct, type) => {
      isLoadData.value = true
      apiClient
        .get(`/api/synchrons-sfdc/${id}`, {
          params: {
            date: date,
            // vendor: vendor,
            // function: funct,
            // type: type,
            page: logPage.value,
            'per-page': logPerPage.value,
          },
        })
        .then(response => {
          const { items, _meta } = response.data
          items.forEach((item, i) => {
            item.key = i
            item.type = type
            item.transdate = date
          })
          console.log('ini log detail', items)
          logs.value = items
          logMeta.value = _meta || { pageCount: 0, totalCount: 0 }
          logPageCount.value = logMeta.value.pageCount
          logTotalCount.value = logMeta.value.totalCount
        })
        .catch(e => {
          message.error('Gagal Mengambil Data Log!')
          console.error(e)
        })
        .finally(() => {
          isLoadData.value = false
          formState.value.title_state = 'Log History'
          modal3Visible.value = true
        })
    }

    const syncrons = ({ id, trans_date, time }) => {
      Modal.confirm({
        title: 'Konfirmasi syncron data',
        content: 'Apakah anda ingin syncron data transaksi ?',
        onOk() {
          // apiClient.get('/api/synchron-opens')
          // .then(response => {
          //   if (response === undefined) {
          //     errorMessage.value = `Kode error 500, No response from server`
          //     return
          //   }
          //   errorMessage.value = null
          //   const { items, _meta } = response.data
          //   const current = new Date();
          //   const now = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
          //   for (var key in items) {
          //     const checkStatus = cek_kuncian(moment(items[key].begin_date).format('DD/MM/YYYY'), moment(items[key].end_date).format('DD/MM/YYYY'), now)
          //     if (checkStatus) {
          //       if (!items[key].status) {
          //         errorMessage.value = `Mohon maaf synchron dikunci mulai tanggal ${moment(items[key].begin_date).format('DD-MM-YYYY')} sampai tanggal ${moment(items[key].end_date).format('DD-MM-YYYY')}`
          //         return
          //       }
          //     }
          //   }
          // })
          // .catch(async error => {
          //   errorMessage.value = null
          //   if (error.response) {
          //     const { status, statusText } = error.response
          //     const message = JSON.parse(await error.response.data.text()).message
          //     errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          //   }
          // })
          do_syncrons(id, trans_date, time)
        },
        onCancel() {},
      })
    }

    const syncEditData = dataId => {
      let formData = {
        model: JSON.parse(objectEdit.value),
      }
      apiClient
        .post(`/api/synchrons-sfdc/${dataId}?_method=PUT`, formData)
        .then(response => {
          modalEditVisible.value = false
          fetchData()
          message.success('Berhasil diubah')
        })
        .catch(error => {
          console.log(error.response.data)
          if (error.response && error.response.data) {
            message.error(error.response.data.message)
          } else {
            message.error('Gagal mengubah!')
          }
        })
        .finally(() => (state.isSync = false))
    }

    const syncronsEdit = async dataItem => {
      // formStateEditTitle.value = []
      state.isSync = true
      await apiClient.get(`/api/synchrons-sfdc/${dataItem.id}`).then(response => {
        let dataResponse = response.data
        objectEdit.value = JSON.stringify(dataResponse.model, null, '\t')
        syncronsId.value = dataResponse.id
        // Object.assign(objectEdit.value, {
        //   id: dataResponse.id,
        // })

        state.isSync = false
      })
      // formStateEditTitle.value.push(object)
      // Object.keys(objectEdit.value).forEach(element => {
      //   formStateEditTitle.value.push(element, {
      //     [element]: objectEdit.value[element],
      //   })
      // })
    }

    // onBeforeMount(() => {
    //     if(formState.value.syncNote === null || formState.value.syncNote === undefined){
    //       formState.value.syncNote = ''
    //     }
    // })

    // const rules = {
    //   syncNote: {
    //     required: true,
    //     message: 'Note tidak boleh kosong!',
    //   },
    // }

    return {
      formStateEditTitle,
      objectEdit,
      modal1Visible,
      setModal1Visible,
      modal2Visible,
      modal3Visible,
      modalEditVisible,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      q,
      tableFilter,
      handleTableChange,
      startRow,
      // onSelectChange,
      state,
      fetchXlsx,
      distributors,
      fetchData,
      detailSyncrons,
      ...toRefs(state),
      start_date,
      end_date,
      search,
      //  rowSelection,
      syncrons,
      status,
      jenis,
      fungsi,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
      dateFormat,
      formState,
      sync_start_date,
      sync_end_date,
      syncFunction,
      syncDistributors,
      syncType,
      onSearch,
      syncEditData,
      syncronsId,
      // syncData,
      syncNote,
      vendorId,
      isLoadData,
      logs,
      logcolumns,
      logPerPage,
      logPageCount,
      logTotalCount,
      logMeta,
      logPage,
      moment,
      innerColumns,
      //
      formRef,
      isDistributor,
      distributor,
      distri,
      isDistributor_khusus,
      syncronsEdit,
    }
  },
})
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.button {
  margin: 0 3px;
}
</style>

<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
.myform :deep(div:not(.has-error) > .ant-form-explain) {
  color: #262d3d;
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
